
.container-for-text-spans {
  span {
    margin: 0 2px;
  }
}

.spacer-w4 {
  width: 4px;
}

.spacer-w8 {
  width: 8px;
}

.spacer-w12 {
  width: 12px;
}

.spacer-w16 {
  width: 16px;
}

.spacer-w24 {
  width: 24px;
}

.spacer-w40 {
  width: 40px;
}

.spacer-h4 {
  height: 4px;
}

.spacer-h8 {
  height: 8px;
}

.spacer-h12 {
  height: 12px;
}

.spacer-h16 {
  height: 16px;
}

.regular-img-size {
  width: 24px;
  height: 24px;
}

.inline-small-img {
  width: 24px;
  height: 20px;
}

.image-16 {
  width: 16px;
  height: 16px;
}

.margin-left-4 {
  margin-left: 4px;
}

.margin-left-8 {
  margin-left: 8px;
}

.margin-left-12 {
  margin-left: 12px;
}

.margin-left-16 {
  margin-left: 16px;
}

.margin-right-4 {
  margin-right: 4px;
}

.margin-right-8 {
  margin-right: 8px;
}

.margin-right-12 {
  margin-right: 12px;
}

.margin-right-16 {
  margin-right: 16px;
}

.margin-top-4 {
  margin-top: 4px;
}

.margin-top-8 {
  margin-top: 8px;
}

.margin-top-12 {
  margin-top: 12px;
}

.margin-top-16 {
  margin-top: 16px;
}

.margin-top-24 {
  margin-top: 24px;
}

.margin-top-36 {
  margin-top: 36px;
}

.margin-bottom-4 {
  margin-bottom: 4px;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}

.margin-bottom-12 {
  margin-bottom: 12px;
}

.margin-8 {
  margin: 8px;
}

.margin-bottom-16 {
  margin-bottom: 16px;
}

.margin-bottom-24 {
  margin-bottom: 24px;
}

.margin-16 {
  margin: 16px;
}

.margin-top-auto {
  margin-top: auto;
}

.padding-top-16 {
  padding-top: 16px;
}

.padding-16 {
  padding: 16px;
}

.padding-x-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.padding-8 {
  padding: 16px;
}

.border-radius-12 {
  border-radius: 12px;
}

.full-width-container {
  width: 100%;
}

.full-height-container {
  height: 100%;
}

.flex {
  display: flex;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
}

.centered-content {
  display: flex;
  align-items: center;
  justify-content: center;

  &.vertical {
    flex-direction: column;
  }
}

.flex-auto-grow {
  flex: 1 1 auto;
}

.flex-grow-no-auto {
  flex: 1 1 0;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.space-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.floating-search-input-container {
  position: sticky;
  position: -webkit-sticky;
  display: flex;
  //width: calc(100% - 32px);
  width: 100%;
  z-index: 2;
  top: -65px;
  margin-bottom: 12px;

  transition: 0.5s;

  &.hidden {
    top: -65px;
  }

  &.unhidden {
    top: 0;
  }
}

.image-view-container {
  position: absolute;
  z-index: 30000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: white;

  background: rgba(0, 0, 0, 0.7);

  img {
    width: unset;
    height: unset;
    max-width: min(1200px, 100%);
    max-height: 100%;
    object-fit: cover;
  }

  .close-icon {
    position: absolute;
    top: 16px;
    left: 16px;
    color: white;
    z-index: 300001;
    cursor: pointer;
  }
}

.gap-4 {
  gap: 4px;
}

.gap-8 {
  gap: 8px;
}

.gap-12 {
  gap: 12px;
}

.gap-16 {
  gap: 16px;
}

.gap-24 {
  gap: 24px;
}
